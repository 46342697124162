import React from 'react';

import { LoggedOutChatScreen } from '@/screen/logged-out-chat-screen/logged-out-chat-screen';
import { useUser } from '@auth0/nextjs-auth0/client';

export default function Home() {
    const { user, isLoading } = useUser();
    if (isLoading) return <div />;
    if (user) return location.replace('/chat' + location.search);
    return <LoggedOutChatScreen />;
}
